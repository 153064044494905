import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const Inventory_Management = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Inventory Managment"
        description="Manage your amazon inventory by knowing when to send the product and how many"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">Amazon Inventory Managment </Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-inventory-01.png"
                    alt="Amazon inventory management"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      The dashboard will show you what to ship, when, and what
                      quantity.
                    </li>
                    <li>
                      Each Product can be assigned a vendor, product components,
                      and a shipping method.
                    </li>
                    <li>
                      If you have a group of products that were sourced from
                      overseas, azszero will try to find the best products
                      combination to ship together so that you can save on
                      shipping.
                    </li>
                    <li>
                      It will automatically generate an Invoice and Packing slip
                      for the items needed to be shipped.
                    </li>
                    <li>
                      If you have a similar ASIN that is sold across multiple
                      marketplaces, it will figure out how many you need to
                      purchase one time.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default Inventory_Management
